import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './AuthorThumbnail.css';

class AuthorThumbnail extends React.Component {
  render() {
    const { name } = this.props;

    // For simplicity, hardcode author image
    // const image = getImage(`../../../static/${avatar}`);
    const image = '../../../static/logos/logo-214x214.png';

    if (image && name) {
      return <StaticImage className="author-thumb" src={image} alt={name} data-pin-nopin="true" />;
    }
    return null;
  }
}

export default AuthorThumbnail;
